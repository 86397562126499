import React from "react"
import { SetStateAction, Dispatch } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

interface DatenschutzProps {
  isSubMenuOpen: boolean
  setSubMenuOpen: Dispatch<SetStateAction<boolean>>
}

const Datenschutz: React.FC<DatenschutzProps> = () => {
  const siteTitle = "Datenschutz"
  const greenVer = true

  return (
    <Layout title={siteTitle} greenVer={greenVer} background="#00aa9b">
      <SEO title={siteTitle} />
      <section className="wrapper greyColor content">
        <br />
        <br />
        <br />
        <br />
        <h1>Datenschutzerklärung</h1>
        <h2 className="contentSubtitle">Stand: August 2023</h2>
        <p>
          In dieser Datenschutzerkl&auml;rung beschreiben wir, die SCHWARZ Optik
          GmbH (nachfolgend Schwarz Optik), wie wir Personendaten erheben und
          bearbeiten. Diese Datenschutzerkl&auml;rung stellt keine
          abschliessende Beschreibung dar; allenfalls regeln andere den
          Datenschutz betreffende Erkl&auml;rungen spezifische Sachverhalte.
          Unter Personendaten werden f&uuml;r die Zwecke dieser
          Datenschutzerkl&auml;rung alle Angaben verstanden, die sich auf eine
          bestimmte oder bestimmbare Person beziehen.
        </p>
        <ol>
          <li data-list-text="1.">
            <h2>
              <a name="bookmark1"></a>Verantwortliche Stelle und Kontakt
            </h2>
            <p>
              Verantwortlich f&uuml;r die Datenbearbeitungen, die wir hier
              beschreiben, ist die SCHWARZ Optik GmbH soweit im Einzelfall
              nichts anderes angegeben ist. Anfragen zum Datenschutz
              k&ouml;nnen, unter Beilage einer Kopie der ID oder des Passes zur
              Identifikation des Nutzers, per Brief oder E-Mail an uns gerichtet
              werden:
            </p>
            <p>
              Rosentalstrasse 8a, 9410 Heiden/Schweiz Tel: +41 71 890 01 11 /
              info@schwarz-optik.ch.
            </p>
          </li>
          <li data-list-text="2.">
            <h2>
              <a name="bookmark2"></a>Erhebung und Bearbeitung von Personendaten
            </h2>
            <p>
              Wir bearbeiten Personendaten insbesondere in den folgenden
              Kategorien von Bearbeitungen.
            </p>
            <ul>
              <li data-list-text="">
                <p>
                  Kundendaten von Kunden, f&uuml;r die wir Leistungen erbringen
                  oder erbracht haben.
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Personendaten, die wir bei der Leistungserbringung indirekt
                  von unseren Kunden erhalten haben.
                </p>
              </li>
              <li data-list-text="">
                <p>Beim Besuch unserer Website</p>
              </li>
              <li data-list-text="">
                <p>Bei der Nutzung unseres Newsletters</p>
              </li>
              <li data-list-text="">
                <p>Bei der Teilnahme an einer Veranstaltung von uns.</p>
              </li>
              <li data-list-text="">
                <p>Wenn wir kommunizieren oder ein Besuch stattfindet.</p>
              </li>
              <li data-list-text="">
                <p>
                  Bei sonstiger vertraglicher Beziehung, z.B. als Lieferant,
                  Dienstleistungserbringer oder Berater.
                </p>
              </li>
              <li data-list-text="">
                <p>Bei Bewerbungen</p>
              </li>
              <li data-list-text="">
                <p>
                  Wenn wir aus gesetzlichen oder regulatorischen Gr&uuml;nden
                  dazu verpflichtet sind.
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Wenn wir unsere Sorgfaltspflichten oder sonstige berechtigte
                  Interessen wahrnehmen, z.B. um Interessenkonflikte zu
                  vermeiden, Geldw&auml;scherei oder sonstige Risiken zu
                  vermeiden, die Datenrichtigkeit sicherzustellen, die
                  Bonit&auml;t zu pr&uuml;fen, die Sicherheit zu
                  gew&auml;hrleisten oder unsere Rechte durchzusetzen.
                </p>
              </li>
            </ul>
            <p>
              Detailliertere Informationen finden Sie in der Beschreibung der
              jeweiligen Kategorien von Bearbeitungen in Ziff. 5
            </p>
          </li>
          <li data-list-text="3.">
            <h2>
              <a name="bookmark3"></a>Kategorien von Personendaten
            </h2>
            <p>
              Welche Personendaten wir bearbeiten ist abh&auml;ngig von Ihrer
              Beziehung zu uns und vom Zweck, f&uuml;r den wir diese bearbeiten.
              Wir bearbeiten nebst ihren Kontaktdaten auch weitere Informationen
              &uuml;ber Sie oder &uuml;ber Personen, die mit Ihnen in einer
              Beziehung stehen. Bei diesen Informationen kann es sich unter
              Umst&auml;nden auch um besonders sch&uuml;tzenswerte Personendaten
              handeln.
            </p>
            <p>
              Wir sammeln folgende Kategorien von Personendaten, abh&auml;ngig
              vom Zweck, f&uuml;r den wir diese bearbeiten:
            </p>
            <ul>
              <li data-list-text="">
                <p>
                  Kontaktinformationen (z.B. Name, Vorname, Adresse,
                  Telefonnummer, E-Mail)
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Kundeninformationen (z.B. Geburtsdatum, Nationalit&auml;t,
                  Zivilstand, Beruf, Titel, Stellenbezeichnung, Pass /
                  ID-Nummer, AHV-Nummer)
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Risikobewertungsdaten (z.B. Bonit&auml;tsinformationen,
                  Handelsregisterdaten)
                </p>
              </li>
              <li data-list-text="">
                <p>Finanzinformationen (z.B. Daten zu Bankverbindungen)</p>
              </li>
              <li data-list-text="">
                <p>
                  Mandatsdaten, je nach Auftrag (z.B. Steuerinformationen,
                  Statuten, Protokolle, Projekte, Vertr&auml;ge, Daten von
                  Mitarbeitenden (z.B. Lohn, Sozialversicherungen),
                  Buchhaltungsdaten, wirtschaftlich Berechtigte,
                  Eigentumsverh&auml;ltnisse)
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Webseitendaten (z.B. IP-Adresse, Ger&auml;teinformation (UDI),
                  Informationen zum Browser, Webseitennutzung (Analyse und
                  Verwendung von Plugins, etc.)
                </p>
              </li>
              <li data-list-text="">
                <p>Bewerbungsdaten (z.B. Lebenslauf, Arbeitszeugnisse)</p>
              </li>
              <li data-list-text="">
                <p>Marketinginformationen (z.B. Anmeldung Newsletter)</p>
              </li>
              <li data-list-text="">
                <p>
                  Sicherheits- und Netzwerkdaten (z.B. Besucherlisten,
                  Zutrittskontrollen, Netzwerk- und Mailscanner,
                  Telefonanruflisten)
                </p>
              </li>
            </ul>
            <p>
              Soweit dies erlaubt ist, entnehmen wir auch &ouml;ffentlich
              zug&auml;nglichen Quellen (z.B. Betreibungsregister,
              Grundb&uuml;cher, Handelsregister, Presse, Internet) gewisse Daten
              oder erhalten solche von unseren Mandanten und deren Mitarbeitern,
              von Beh&ouml;rden, (Schieds-)Gerichten und sonstigen Dritten.
              Nebst den Daten von Ihnen, die Sie uns direkt geben, umfassen die
              Kategorien von Personendaten, die wir von Dritten &uuml;ber Sie
              erhalten, insbesondere Angaben aus &ouml;ffentlichen Registern,
              Angaben, die wir im Zusammenhang mit beh&ouml;rdlichen und
              gerichtlichen Verfahren erfahren, Angaben im Zusammenhang mit
              ihren beruflichen Funktionen und Aktivit&auml;ten (damit wir z.B.
              mit Ihrer Hilfe Gesch&auml;fte mit Ihrem Arbeitgeber abschliessen
              und abwickeln k&ouml;nnen), Angaben &uuml;ber Sie in Korrespondenz
              und Besprechungen mit Dritten, Bonit&auml;tsausk&uuml;nfte,
              Angaben &uuml;ber Sie, die uns Personen aus Ihrem Umfeld (Familie,
              Berater, Rechtsvertreter, etc.) geben, damit wir Vertr&auml;ge mit
              Ihnen oder unter Einbezug von Ihnen abschliessen oder abwickeln
              k&ouml;nnen (z.B. Referenzen, Ihre Adresse f&uuml;r Lieferungen,
              Vollmachten) Angaben zur Einhaltung gesetzlicher Vorgaben wie etwa
              der Geldw&auml;schereibek&auml;mpfung und Exportrestriktionen,
              Angaben von Banken, Versicherungen, Vertriebs- und anderen
              Vertragspartnern von uns zur Inanspruchnahme oder Erbringung von
              Leistungen durch Sie (z.B. erfolgte Zahlungen, erfolgte
              K&auml;ufe), Angaben aus Medien und Internet zu Ihrer Person
              (soweit dies im konkreten Fall angezeigt ist, z.B. im Rahmen einer
              Bewerbung etc.), Ihre Adressen und ggf. Interessen und weitere
              soziodemographische Daten (f&uuml;r Marketing), Daten im
              Zusammenhang mit der Benutzung der Website (z.B. IP-Adresse,
              MAC-Adresse des Smartphones oder Computers, Angaben zu Ihrem
              Ger&auml;t und Einstellungen, Cookies, Datum und Zeit des Besuchs,
              abgerufene Seiten und Inhalte, benutzte Funktionen, verweisende
              Website, Standortangaben).
            </p>
          </li>
          <li data-list-text="4.">
            <h2>
              <a name="bookmark4"></a>Zwecke der Datenbearbeitung und
              Rechtsgrundlage<a name="bookmark5"></a>&zwnj;
            </h2>
            <ol>
              <li data-list-text="4.1.">
                <h2>Erbringung von Dienstleistungen</h2>
                <p>
                  Wir bearbeiten in erster Linie die Personendaten, die wir im
                  Rahmen unserer Mandatsbeziehungen mit unseren Kunden und
                  sonstigen Vertragsbeziehungen mit Gesch&auml;ftspartnern von
                  diesen und weiteren daran beteiligten Personen erhalten.
                </p>
                <p>
                  Bei den Personendaten unserer Kunden handelt es sich
                  insbesondere um folgende Informationen:
                </p>
                <ul>
                  <li data-list-text="">
                    <p>
                      Kontaktinformationen (z.B. Name, Vorname, Adresse,
                      Telefonnummer, E-Mail, sonstige Kontaktinformationen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Pers&ouml;nliche Informationen (z.B. Geburtsdatum,
                      Nationalit&auml;t, Zivilstand, Beruf, Titel,
                      Stellenbezeichnung, Pass / ID-Nummer, AHV-Nummer,
                      famili&auml;re Verh&auml;ltnisse, etc.)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Daten zur Risikobewertung (z.B.
                      Bonit&auml;tsinformationen, Handelsregisterdaten,
                      Sanktionslisten, spezialisierte Datenbanken, Daten aus dem
                      Internet)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Finanzinformationen (z.B. Daten zu Bankverbindungen,
                      Investments oder Beteiligungen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Mandatsdaten, je nach Auftrag z.B. Steuerinformationen,
                      Statuten, Protokolle, Daten von Mitarbeitenden (z.B. Lohn,
                      Sozialversicherungen), Buchhaltungsdaten, etc.
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Besonders sch&uuml;tzenswerte Personendaten: Unter diesen
                      Personendaten k&ouml;nnen sich auch besonders
                      sch&uuml;tzenswerte Personendaten befinden, wie bspw.
                      Daten zur Gesundheit, zu religi&ouml;sen Ansichten oder zu
                      Massnahmen der sozialen Hilfe, insbesondere, wenn wir
                      Leistungen im Bereich Lohnverarbeitung oder Buchhaltung
                      erbringen.
                    </p>
                    <p>
                      Diese Personendaten bearbeiten wir f&uuml;r die
                      beschriebenen Zwecke gest&uuml;tzt auf die folgenden
                      Rechtsgrundlagen:
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Abschluss oder Abwicklung eines Vertrages mit der
                      betroffenen Person bzw. zugunsten der betroffenen Person,
                      inkl. Vertragsanbahnung und allf&auml;llige Durchsetzung
                      (z.B. Beratung, Treuhand)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Erf&uuml;llung einer gesetzlichen Verpflichtung (z. B.
                      wenn wir unsere Pflichten als Revisionsstelle wahrnehmen
                      oder verpflichtet sind, Informationen offen zu legen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Wahrung berechtigter Interessen, (z.B. f&uuml;r
                      administrative Zwecke, um unsere Qualit&auml;t zu
                      verbessern, die Sicherheit zu gew&auml;hrleisten,
                      Risikomanagement zu betreiben, unsere Rechte
                      durchzusetzen, uns gegen Anspr&uuml;che zu verteidigen
                      oder um m&ouml;gliche Interessenkonflikte zu pr&uuml;fen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Einwilligung (z.B. um ihnen Marketinginformationen
                      zuzusenden).
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="4.2.">
                <h2>
                  <a name="bookmark6"></a>Indirekte Datenbearbeitung aus
                  Leistungserbringung
                </h2>
                <p>
                  Wenn wir Leistungen f&uuml;r unsere Kunden erbringen, kann es
                  vorkommen, dass wir auch Personendaten bearbeiten, die wir
                  nicht direkt bei den betroffenen Personen erhoben haben oder
                  Personendaten von Drittpersonen. Es handelt sich bei diesen
                  Drittpersonen in der Regel um Mitarbeitende, Kontaktpersonen,
                  Familienmitglieder oder Personen, die aus anderen Gr&uuml;nden
                  mit den Kunden oder den betroffenen Personen in einer
                  Beziehung stehen. Diese Personendaten ben&ouml;tigen wir, um
                  Vertr&auml;ge mit unseren Kunden zu erf&uuml;llen. Wir
                  erhalten diese Personendaten von unseren Kunden oder von
                  Dritten, die von unseren Kunden beauftragt werden.
                  Drittpersonen, deren Informationen wir zu diesem Zweck
                  bearbeiten, werden von unseren Kunden dar&uuml;ber informiert,
                  dass wir ihre Daten bearbeiten. Unsere Kunden k&ouml;nnen
                  daf&uuml;r auf diese Datenschutzerkl&auml;rung verweisen.
                </p>
                <p>
                  Bei den Personendaten der Personen, die mit unseren Kunden in
                  einer Beziehung stehen, handelt es sich insbesondere um
                  folgende Informationen:
                </p>
                <ul>
                  <li data-list-text="">
                    <p>
                      Kontaktinformationen (z.B. Name, Vorname, Adresse,
                      Telefonnummer, E-Mail, sonstige Kontaktinformationen,
                      Marketingdaten)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Pers&ouml;nliche Informationen (z.B. Geburtsdatum,
                      Nationalit&auml;t, Zivilstand, Beruf, Titel,
                      Stellenbezeichnung, Pass / ID-Nummer, AHV-Nummer,
                      famili&auml;re Verh&auml;ltnisse, etc.)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Finanzinformationen (z.B. Daten zu Bankverbindungen,
                      Investments oder Beteiligungen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Mandatsdaten, je nach Auftrag z.B. Steuerinformationen,
                      Statuten, Protokolle, Daten von Mitarbeitenden (z.B. Lohn,
                      Sozialversicherungen), Buchhaltungsdaten
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Besonders sch&uuml;tzenswerte Personendaten: Unter diesen
                      Personendaten k&ouml;nnen sich auch besonders
                      sch&uuml;tzenswerte Personendaten befinden, wie bspw.
                      Daten zur Gesundheit, zu religi&ouml;sen Ansichten oder zu
                      Massnahmen der sozialen Hilfe, insbesondere, wenn wir
                      Leistungen im Bereich Lohnverarbeitung oder Buchhaltung
                      erbringen.
                    </p>
                    <p>
                      Diese Personendaten bearbeiten wir f&uuml;r die
                      beschriebenen Zwecke gest&uuml;tzt auf die folgenden
                      Rechtsgrundlagen:
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Abschluss oder Abwicklung eines Vertrages mit der
                      betroffenen Person bzw. zugunsten der betroffenen Person
                      (z.B. wenn wir unsere vertraglichen Pflichten wahrnehmen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Erf&uuml;llung einer gesetzlichen Verpflichtung (z.B. wenn
                      wir unsere Pflichten als Revisionsstelle wahrnehmen oder
                      verpflichtet sind, Informationen offen zu legen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Wahrung berechtigter Interessen, insbesondere unser
                      Interesse, gegen&uuml;ber unseren Kunden eine optimale
                      Leistung zu erbringen.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="4.3.">
                <h2>
                  <a name="bookmark7"></a>Nutzung unserer Webseite
                </h2>
                <p>
                  Um unsere Webseite zu nutzen, m&uuml;ssen keine Personendaten
                  offengelegt werden. Der Server erfasst jedoch mit jedem Aufruf
                  eine Reihe von Benutzerinformationen, welche
                  vor&uuml;bergehend in den Logfiles des Servers gespeichert
                  werden.
                </p>
                <p>
                  Bei der Nutzung dieser allgemeinen Informationen findet keine
                  Zuordnung zu einer bestimmten Person statt. Die Erfassung
                  dieser Informationen bzw. Daten ist technisch erforderlich, um
                  unsere Webseite anzuzeigen und dessen Stabilit&auml;t und
                  Sicherheit zu gew&auml;hrleisten. Diese Informationen werden
                  zudem erhoben, um die Webseite zu verbessern und ihre Nutzung
                  zu analysieren.
                </p>
                <p>
                  Dabei handelt es sich insbesondere um folgende Informationen:
                </p>
                <ul>
                  <li data-list-text="">
                    <p>
                      Kontaktinformationen (z.B. Name, Vorname, Adresse,
                      Telefonnummer, E-Mail)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Weitere Informationen, die sie uns via die Webseite
                      &uuml;bermitteln
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Automatisch an uns oder unsere Dienstleister
                      &uuml;bermittelte technische Informationen, Informationen
                      zum Nutzerverhalten oder der Einstellungen der Webseite
                      (z.B. IP-Adresse, UDI, Ger&auml;tetyp, Browser, Anzahl
                      Klicks auf der Seite, &Ouml;ffnen des Newsletters, Klick
                      auf Links, etc.
                    </p>
                    <p>
                      Diese Personendaten bearbeiten wir f&uuml;r die
                      beschriebenen Zwecke gest&uuml;tzt auf die folgenden
                      Rechtsgrundlagen:
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Wahrung berechtigter Interessen, (z.B. f&uuml;r
                      administrative Zwecke, um unsere Qualit&auml;t zu
                      verbessern, Daten zu analysieren oder unsere
                      Dienstleistungen bekannt zu machen)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Einwilligung (z.B. in die Nutzung von Cookies oder den
                      Newsletter).
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="4.4.">
                <h2>
                  <a name="bookmark8"></a>Newsletter-Nutzung
                </h2>
                <p>
                  Soweit Sie unseren Newsletter abonnieren, nutzen wir Ihre
                  E-Mail-Adresse und weitere Kontaktdaten, um Ihnen den
                  Newsletter zu senden. Sie k&ouml;nnen unseren Newsletter mit
                  Ihrer Einwilligung abonnieren. Pflichtangabe f&uuml;r die
                  &Uuml;bersendung des Newsletters sind Ihr vollst&auml;ndiger
                  Name sowie Ihre E-Mailadresse, die wir nach Ihrer Anmeldung
                  speichern. Rechtsgrundlagen f&uuml;r die Verarbeitung Ihrer
                  Daten im Zusammenhang mit unserem Newsletter bildet Ihre
                  Einwilligung in die &Uuml;bersendung des Newsletters. Diese
                  k&ouml;nnen Sie jederzeit widerrufen und den Newsletter
                  abbestellen.
                </p>
              </li>
              <li data-list-text="4.5.">
                <h2>
                  <a name="bookmark9"></a>Teilnahme an Veranstaltungen
                </h2>
                <p>
                  Wenn Sie an einer von uns organisierten Veranstaltung
                  teilnehmen, erheben wir Personendaten, um die Veranstaltung zu
                  organisieren, durchzuf&uuml;hren und Ihnen allenfalls
                  nachtr&auml;glich zus&auml;tzliche Informationen zu senden.
                  Wir verwenden Ihre Informationen ebenfalls, um Sie auf weitere
                  Veranstaltungen hinzuweisen. Es kann sein, dass Sie an diesen
                  Veranstaltungen von uns fotografiert oder gefilmt werden und
                  wir dieses Bildmaterial intern oder extern
                  ver&ouml;ffentlichen.
                </p>
                <p>
                  Dabei handelt es sich insbesondere um folgende Informationen:
                </p>
                <ul>
                  <li data-list-text="">
                    <p>
                      Kontaktinformationen (z.B. Name, Vorname, Adresse,
                      Telefonnummer, E-Mail)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Pers&ouml;nliche Informationen (z.B. Beruf, Funktion,
                      Titel, Arbeitgeberfirma, Essgewohnheiten)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>Bilder oder Videos</p>
                  </li>
                  <li data-list-text="">
                    <p>Zahlungsinformationen (z.B. Bankverbindung).</p>
                    <p>
                      Diese Personendaten bearbeiten wir f&uuml;r die
                      beschriebenen Zwecke gest&uuml;tzt auf die folgenden
                      Rechtsgrundlagen:
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Erf&uuml;llung einer vertraglichen Pflicht mit der
                      betroffenen Person bzw. zugunsten der betroffenen Person,
                      inkl. Vertragsanbahnung und allf&auml;llige Durchsetzung
                      (Erm&ouml;glichung der Teilnahme an der Veranstaltung)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Wahrung berechtigter Interessen (z.B. Durchf&uuml;hrung
                      von Veranstaltungen, Verbreitung von Informationen
                      &uuml;ber unsere Veranstaltung, Erbringung von
                      Dienstleistungen, effiziente Organisation)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Einwilligung (z.B. um ihnen Marketinginformationen
                      zuzusenden oder Bildmaterial zu erstellen).
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="4.6.">
                <h2>
                  <a name="bookmark10"></a>Direkte Kommunikation und Besuche
                </h2>
                <p>
                  Wenn Sie mit uns Kontakt aufnehmen (z.B. via Telefon, E-Mail
                  oder Chat) oder wir Sie kontaktieren, bearbeiten wir die
                  daf&uuml;r notwendigen Personendaten. Wir bearbeiten diese
                  Personendaten ebenfalls, wenn Sie uns besuchen. In diesem Fall
                  kann es sein, dass Sie vor ihrem Besuch oder am Empfang Ihre
                </p>
                <p>
                  Kontaktdaten hinterlassen m&uuml;ssen. Diese werden von uns
                  f&uuml;r eine gewisse Zeit aufbewahrt, um unsere Infrastruktur
                  und unsere Informationen zu sch&uuml;tzen.
                </p>
                <p>
                  F&uuml;r die Durchf&uuml;hrung von Telefonkonferenzen,
                  Online-Meetings, Videokonferenzen und/oder Webinare
                  (&laquo;Online-Meetings&raquo;) nutzen wir den Dienst
                  &laquo;Zoom&raquo; oder &laquo;Microsoft Teams&raquo;.
                </p>
                <p>Wir bearbeiten dabei insbesondere folgende Informationen:</p>
                <ul>
                  <li data-list-text="">
                    <p>
                      Kontaktinformationen (z.B. Name, Vorname, Adresse,
                      Telefonnummer, E-Mail)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Randdaten zur Kommunikation (z.B. IP-Adresse, Dauer der
                      Kommunikation, Kommunikationskanal)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Aufzeichnungen von Gespr&auml;chen, z.B. bei
                      Videokonferenzen
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Andere Informationen, die der Nutzer w&auml;hrend der
                      Verwendung des Videokonferenzdienstes hochl&auml;dt,
                      bereitstellt oder erstellt sowie f&uuml;r die Wartung des
                      bereitgestellten Dienstes verwendete Metadaten
                      Zus&auml;tzliche Ausk&uuml;nfte &uuml;ber die Bearbeitung
                      personenbezogener Daten durch &laquo;Zoom&raquo; oder
                      &laquo;Microsoft&raquo; Teams finden sich in deren
                      Datenschutzerkl&auml;rungen.
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Pers&ouml;nliche Informationen (z.B. Beruf, Funktion,
                      Titel, Arbeitgeberfirma)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>Zeitpunkt und Grund des Besuchs.</p>
                    <p>
                      Diese Personendaten bearbeiten wir f&uuml;r die
                      beschriebenen Zwecke gest&uuml;tzt auf die folgenden
                      Rechtsgrundlagen:
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Erf&uuml;llung einer vertraglichen Pflicht mit der
                      betroffenen Person bzw. zugunsten der betroffenen Person,
                      inkl. Vertragsanbahnung und allf&auml;llige Durchsetzung
                      (Erbringung einer Dienstleistung)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Wahrung berechtigter Interessen (z.B. Sicherheit,
                      Nachvollziehbarkeit sowie Abwicklung und Administration
                      von Kundenbeziehungen).
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="4.7.">
                <h2>
                  <a name="bookmark11"></a>Bewerbungen
                </h2>
                <p>
                  Sie k&ouml;nnen per Post oder &uuml;ber die auf unserer
                  Webseite angegebene E-Mail-Adresse Ihre Bewerbung f&uuml;r
                  eine Stelle bei uns einreichen. Die Bewerbungsunterlagen und
                  s&auml;mtliche damit an uns bekanntgegebenen Personendaten
                  werden streng vertraulich behandelt, keinem Dritten
                  bekanntgegeben und nur zum Zweck der Bearbeitung Ihrer
                  Bewerbung f&uuml;r eine Anstellung bei uns bearbeitet. Ohne
                  Ihre gegenteilige Zustimmung wird Ihr Bewerbungsdossier nach
                  dem Abschluss des Bewerbungsverfahrens entweder an Sie
                  zur&uuml;ckgesendet oder gel&ouml;scht/vernichtet, soweit es
                  nicht einer gesetzlichen Aufbewahrungspflicht unterliegt.
                  Rechtsgrundlagen f&uuml;r die Bearbeitung Ihrer Daten bilden
                  Ihre Einwilligung, die Erf&uuml;llung des Vertrages mit Ihnen
                  und unsere berechtigten Interessen.
                </p>
                <p>Wir bearbeiten dabei insbesondere folgende Informationen:</p>
                <ul>
                  <li data-list-text="">
                    <p>
                      Kontaktinformationen (z.B. Name, Vorname, Adresse,
                      Telefonnummer, E-Mail)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Pers&ouml;nliche Informationen (z.B. Beruf, Funktion,
                      Titel, Arbeitgeberfirma)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Bewerbungsunterlagen (z.B. Motivationsschreiben,
                      Zeugnisse, Diplome, Lebenslauf)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Bewertungsinformationen (z.B. Beurteilung Personalberater,
                      Referenzausk&uuml;nfte, Assessments)
                    </p>
                    <p>
                      Diese Personendaten bearbeiten wir f&uuml;r die
                      beschriebenen Zwecke gest&uuml;tzt auf die folgenden
                      Rechtsgrundlagen:
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Wahrung berechtigter Interessen (z.B. Anstellung neuer
                      Mitarbeitenden)
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>Einwilligung.</p>
                  </li>
                </ul>
              </li>
              <li data-list-text="4.8.">
                <h2>
                  <a name="bookmark12"></a>Lieferanten, Dienstleister, sonstige
                  Vertragspartner
                </h2>
                <p>
                  Wenn wir mit Ihnen einen Vertrag schliessen, damit Sie eine
                  Dienstleistung f&uuml;r uns erbringen, bearbeiten wir
                  Personendaten von Ihnen oder Ihren Mitarbeitenden. Diese
                  ben&ouml;tigen wir, um mit Ihnen zu kommunizieren und Ihre
                  Leistungen in Anspruch zu nehmen. Wir bearbeiten diese
                  Personendaten unter
                </p>
                <p>
                  Umst&auml;nden auch um zu pr&uuml;fen, ob ein
                  Interessenkonflikt im Zusammenhang mit unserer T&auml;tigkeit
                  als Revisionsstelle vorliegen k&ouml;nnte und um
                  sicherzustellen, dass wir mit der Zusammenarbeit keine
                  ungewollten Risiken, z.B. hinsichtlich Geldw&auml;scherei oder
                  Sanktionen eingehen.
                </p>
                <p>Wir bearbeiten dabei insbesondere folgende Informationen:</p>
                <ul>
                  <li data-list-text="">
                    <p>
                      Kontaktinformationen (z.B. Name, Vorname, Adresse,
                      Telefonnummer, E-Mail).
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Pers&ouml;nliche Informationen (z.B. Beruf, Funktion,
                      Titel, Arbeitgeberfirma).
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>Finanzinformationen (z.B. Daten zu Bankverbindungen).</p>
                    <p>
                      Diese Personendaten bearbeiten wir f&uuml;r die
                      beschriebenen Zwecke gest&uuml;tzt auf die folgenden
                      Rechtsgrundlagen:
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Abschluss oder Abwicklung eines Vertrages mit der
                      betroffenen Person bzw. zugunsten der betroffenen Person,
                      inkl. Vertragsanbahnung und allf&auml;llige Durchsetzung
                    </p>
                  </li>
                  <li data-list-text="">
                    <p>
                      Wahrung berechtigter Interessen, (z.B. Vermeidung von
                      Interessenkonflikten, Schutz des Unternehmens,
                      Durchsetzung von Rechtsanspr&uuml;chen).
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li data-list-text="5.">
            <h2>
              <a name="bookmark13"></a>Tracking-Technologien
            </h2>
            <p>
              Wir setzen auf unserer Website Cookies ein. Hierbei handelt es
              sich um kleine Dateien, die Ihr Browser automatisch erstellt und
              die auf Ihrem Endger&auml;t (Laptop, Tablet, Smartphone o.&auml;.)
              gespeichert werden, wenn Sie unsere Seite besuchen.
            </p>
            <p>
              Im Cookie werden Informationen abgelegt, die sich jeweils im
              Zusammenhang mit dem spezifisch eingesetzten Endger&auml;t
              ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar
              Kenntnis von Ihrer Identit&auml;t erhalten. Der Einsatz von
              Cookies dient einerseits dazu, die Nutzung unseres Angebots
              f&uuml;r Sie angenehmer zu gestalten. So setzen wir sogenannte
              Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten
              unserer Website bereits besucht haben. Diese werden nach Verlassen
              unserer Seite automatisch gel&ouml;scht.
            </p>
            <p>
              Dar&uuml;ber hinaus setzen wir ebenfalls zur Optimierung der
              Benutzerfreundlichkeit tempor&auml;re Cookies ein, die f&uuml;r
              einen bestimmten festgelegten Zeitraum auf Ihrem Endger&auml;t
              gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere
              Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie
              bereits bei uns waren und welche Eingaben und Einstellungen sie
              get&auml;tigt haben, um diese nicht noch einmal eingeben zu
              m&uuml;ssen. Zum anderen setzten wir Cookies ein, um die Nutzung
              unserer Website statistisch zu erfassen und zum Zwecke der
              Optimierung unseres Angebotes f&uuml;r Sie auszuwerten. Diese
              Cookies erm&ouml;glichen es uns, bei einem erneuten Besuch unserer
              Seite automatisch zu erkennen, dass Sie bereits bei uns waren.
              Diese Cookies werden nach einer jeweils definierten Zeit
              automatisch gel&ouml;scht.
            </p>
            <p>
              Die durch Cookies verarbeiteten Daten sind f&uuml;r die genannten
              Zwecke erforderlich. Die meisten Browser akzeptieren Cookies
              automatisch. Sie k&ouml;nnen Ihren Browser jedoch so
              konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert
              werden oder stets ein Hinweis erscheint, bevor ein neues Cookie
              angelegt wird. Die vollst&auml;ndige Deaktivierung von Cookies
              kann jedoch dazu f&uuml;hren, dass Sie nicht alle Funktionen
              unserer Website nutzen k&ouml;nnen.
            </p>
          </li>
          <li data-list-text="6.">
            <h2>
              <a name="bookmark14"></a>Web- und Newsletteranalyse
            </h2>
            <p>
              Um Aufschluss &uuml;ber die Nutzung unserer Webseite zu erhalten,
              unser Internet-Angebot zu verbessern sowie Sie auch auf Webseiten
              von Dritten oder auf Social Media mit Werbung ansprechen zu
              k&ouml;nnen, setzen wir folgende Web Analyse-Tools und
              Re-Targeting-Technologien ein: Google Analytics, und unsere
              Newsletter.
            </p>
            <p>
              Diese Tools werden von Drittanbietern zur Verf&uuml;gung gestellt.
              In der Regel werden die zu diesem Zweck erhobenen Informationen
              &uuml;ber die Nutzung einer Webseite durch den Einsatz von Cookies
              oder &auml;hnlichen Technologien an den Server des Drittanbieters
              &uuml;bermittelt. Je nach Drittanbieter befinden sich diese Server
              im Ausland.
            </p>
            <p>
              Die &Uuml;bermittlung der Daten erfolgt normalerweise unter
              K&uuml;rzung der IP-Adressen, wodurch die Identifikation einzelner
              Endger&auml;te verhindert wird. Eine &Uuml;bertragung dieser
              Informationen durch Drittanbieter findet nur aufgrund gesetzlicher
              Vorschriften oder im Rahmen der Auftragsdatenbearbeitung statt.
            </p>
            <ol>
              <li data-list-text="6.1.">
                <h2>
                  <a name="bookmark15"></a>Google Analytics
                </h2>
                <p>
                  Wir nutzen auf unseren Webseiten Google Analytics den
                  Webanalysedienst der Google LLC, Mountain View, California,
                  USA, zust&auml;ndig f&uuml;r Europa ist Google Limited Ireland
                  (&laquo;Google&raquo;). Zur Deaktivierung von Google
                  Analytiscs stellt Google unter
                  https://tools.google.com/dlpage/gaoptout?hl=de ein
                  Browser-Plug-In zur Verf&uuml;gung. Google Analytics verwendet
                  Cookies. Das sind kleine Textdateien, die es m&ouml;glich
                  machen, auf dem Endger&auml;t des Nutzers spezifische, auf den
                  Nutzer bezogene Informationen zu speichern. Diese
                  erm&ouml;glichen eine Analyse der Nutzung unseres
                  Websiteangebotes durch Google. Die durch Cookies erfassten
                  Informationen &uuml;ber die Nutzung unserer Seiten
                  (einschliesslich Ihrer IP-Adresse) werden in der Regel an
                  einen Server von Google in den USA &uuml;bertragen und dort
                  gespeichert. Wir weisen darauf hin, dass auf dieser Website
                  Google Analytics um den Code &laquo;gat.anonymizeIp();&raquo;
                  erweitert wurde, um eine anonymisierte Erfassung von
                  IP-Adressen (sog. IP-Masking) zu gew&auml;hrleisten. Ist die
                  Anonymisierung aktiv, k&uuml;rzt Google IP-Adressen innerhalb
                  von Mitgliedstaaten der Europ&auml;ischen Union oder in
                  anderen Vertragsstaaten des Abkommens &uuml;ber den
                  Europ&auml;ischen Wirtschaftsraum, weswegen keine
                  R&uuml;ckschl&uuml;sse auf Ihre Identit&auml;t m&ouml;glich
                  sind. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an
                  einen Server von Google in den USA &uuml;bertragen und dort
                  gek&uuml;rzt. Google wird unter Umst&auml;nden ihre IP-Adresse
                  mit anderen Daten von Google in Verbindung bringen. F&uuml;r
                  Daten&uuml;bermittlungen in die USA hat sich Google
                  verpflichtet, die EU-Standardvertragsklauseln zu unterzeichnen
                  und einzuhalten. .
                </p>
              </li>
              <li data-list-text="6.2.">
                <h2>
                  <a name="bookmark16"></a>Google Maps
                </h2>
                <p>
                  Auf unserer Website verwenden wir Google Maps (API) von Google
                  Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                  zust&auml;ndig f&uuml;r Europa ist Google Limited Ireland,
                  &laquo;Google&raquo;). Google Maps ist ein Webdienst zur
                  Darstellung von interaktiven (Land-)Karten, um geographische
                  Informationen visuell darzustellen. &Uuml;ber die Nutzung
                  dieses Dienstes wird ihnen unser Standort angezeigt und eine
                  etwaige Anfahrt erleichtert. Bereits beim Aufrufen derjenigen
                  Unterseiten, in die die Karte von Google Maps eingebunden ist,
                  werden Informationen &uuml;ber Ihre Nutzung unserer Website
                  (wie z.B. Ihre IP-Adresse) an Server von Google in den USA
                  &uuml;bertragen und dort gespeichert. Dies erfolgt
                  unabh&auml;ngig davon, ob Google ein Nutzerkonto bereitstellt,
                  &uuml;ber das Sie eingeloggt sind, oder ob kein Nutzerkonto
                  besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre
                  Daten direkt ihrem Konto zugeordnet. Wenn Sie die Zuordnung
                  mit Ihrem Profil bei Google nicht w&uuml;nschen, m&uuml;ssen
                  Sie sich vor Aktivierung des Buttons ausloggen. Google
                  speichert Ihre Daten (selbst f&uuml;r nicht eingeloggte
                  Nutzer) als Nutzungsprofile und wertet diese aus.
                </p>
                <p>
                  F&uuml;r Daten&uuml;bermittlungen in die USA hat sich Google
                  verpflichtet, die EU-Standardvertragsklauseln zu unterzeichnen
                  und einzuhalten.
                </p>
              </li>
              <li data-list-text="6.3.">
                <h2>
                  <a name="bookmark17"></a>Social Media Plugins
                </h2>
                <p>
                  Auf unserer Webseite werden sogenannte Social Media Plugins
                  (&laquo;Plugins&raquo;) von Drittanbietern verwendet. Die
                  Plugins sind anhand des Logos des jeweiligen sozialen
                  Netzwerks erkennbar. &Uuml;ber die Plugins bieten wir Ihnen
                  die M&ouml;glichkeit, mit den sozialen Netzwerken und anderen
                  Nutzern zu interagieren. Wir setzen auf unserer Webseite
                  folgende Plugins ein: Facebook, Instagram, X, LinkedIn,
                  YouTube. Wenn Sie unsere Webseite aufrufen, stellt Ihr Browser
                  eine direkte Verbindung zu den Servern des Drittanbieters her.
                  Der Inhalt des Plugins (z.B. YouTube Videos) wird vom
                  jeweiligen Drittanbieter direkt an Ihren Browser
                  &uuml;bermittelt und in die Seite eingebunden.
                </p>
                <p>
                  Die Datenweitergabe zur Anzeige von Inhalten (z.B.
                  Publikationen auf Twitter) erfolgt unabh&auml;ngig
                </p>
                <p>
                  davon, ob Sie ein Konto bei Drittanbieter besitzen und dort
                  eingeloggt sind. Wenn Sie beim Drittanbieter eingeloggt sind,
                  werden Ihre bei uns erhobenen Daten zudem direkt Ihrem beim
                  Drittanbieter bestehenden Konto zugeordnet. Wenn Sie die
                  Plugins aktivieren werden die Informationen ausserdem in dem
                  sozialen Netzwerk ver&ouml;ffentlicht und dort Ihren Kontakten
                  angezeigt. Zweck und Umfang der Datenerhebung und die weitere
                  Bearbeitung und Nutzung der Daten durch die Drittanbieter
                  sowie ihre diesbez&uuml;glichen Rechte und
                  Einstellungsm&ouml;glichkeiten zum Schutz Ihrer
                  Privatsph&auml;re entnehmen Sie bitte den
                  Daten-schutzhinweisen der Drittanbieter. Der Drittanbieter
                  speichert die &uuml;ber Sie erhobenen Daten als
                  Nutzungsprofile und nutzt diese f&uuml;r Zwecke der Werbung,
                  Marktforschung und/oder bedarfsgerechten Gestaltung seiner
                  Website. Eine solche Auswertung erfolgt insbesondere auch
                  f&uuml;r nicht eingeloggte Nutzer zur Darstellung von
                  bedarfsgerechter Werbung und um andere Nutzer des sozialen
                  Netzwerks &uuml;ber Ihre Aktivit&auml;ten auf unserer Website
                  zu informieren. Wenn Sie verhindern m&ouml;chten, dass die
                  Drittanbieter die &uuml;ber unseren Webauftritt gesammelten
                  Daten nicht Ihrem pers&ouml;nlichen Profil in dem jeweiligen
                  sozialen Netzwerk zuordnen, m&uuml;ssen Sie sich vor ihrem
                  Besuch unserer Webseite beim entsprechenden sozialen Netzwerk
                  ausloggen. Sie k&ouml;nnen das Laden der Plugins auch mit
                  spezialisierten Add-Ons f&uuml;r Ihren Browser wie
                  beispielsweise
                </p>
                <p>
                  &laquo;Ghostery&raquo; ( https://www.ghostery.com/ ) oder
                  &laquo;NoScript&raquo; ( http://noscript.net/) komplett
                  verhindern.
                </p>
              </li>
              <li data-list-text="6.4.">
                <h2>
                  <a name="bookmark18"></a>Newsletter Tracking
                </h2>
                <p>
                  F&uuml;r den Versand unserer Newsletter verwenden wir die
                  Software Brevo. Mit dieser Software k&ouml;nnen Newsletter
                  versendet und analysiert werden. Um diese Analyse
                  durchzuf&uuml;hren erheben wir Ger&auml;te- und Zugangsdaten.
                  Um diese zu erheben enth&auml;lt der Newsletter einen Pixel.
                  Der Newsletter bzw. die aus diesem Newsletter erreichbaren
                  Webseiten werden zudem mit Cookies getrackt. Ein Pixel ist
                  eine Bilddatei, welche auf dem Ger&auml;t des Empf&auml;ngers
                  gespeichert wird.
                </p>
                <p>
                  Mit Hilfe dieser Technologien erhalten wir die Information, ob
                  der Newsletter angekommen ist, ge&ouml;ffnet wurde und welche
                  Inhalte angeklickt wurden. Diese Informationen verwenden wir,
                  um unseren Newsletter und unsere Angebote zu verbessern.
                </p>
                <p>
                  Das Setzen eines Pixels kann verhindert werden, indem im
                  Mailprogramm HTML deaktiviert wird (je nach Mailprogramm
                  verschieden).
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="7.">
            <h2>
              <a name="bookmark19"></a>Datenweitergabe und
              Daten&uuml;bermittlung
            </h2>
            <p>
              Wir geben Ihre Daten nur an Dritte weiter, wenn dies zur
              Erbringung unserer Leistung notwendig ist, wenn diese Dritten
              f&uuml;r uns eine Dienstleistung erbringen, wenn wir dazu
              gesetzlich oder beh&ouml;rdlich verpflichtet sind oder wenn wir
              ein &uuml;berwiegendes Interesse an der Weitergabe der
              Personendaten haben. Wir werden Personendaten ebenfalls an Dritte
              weitergeben, wenn Sie dazu ihre Einwilligung erteilt oder uns dazu
              aufgefordert haben.
            </p>
            <p>
              Nicht alle Personendaten werden standardm&auml;ssig
              verschl&uuml;sselt &uuml;bermittelt. Sofern nicht explizit mit dem
              Kunden anders vereinbart, werden Buchhaltungsdaten,
              Lohnadministrationsdaten, Lohnabrechnungen und -ausweise
              unverschl&uuml;sselt &uuml;bermittelt.
            </p>
            <p>
              Folgende Kategorien von Empf&auml;nger k&ouml;nnen Personendaten
              von uns erhalten:
            </p>
            <ul>
              <li data-list-text="&bull;">
                <p>
                  Zweigniederlassungen, Tochter- oder Schwestergesellschaften.
                </p>
              </li>
              <li data-list-text="&bull;">
                <p>
                  Dienstleister (z.B. IT-Dienstleister, Hosting-Provider,
                  Lieferanten, Berater, Rechtsanw&auml;lte, Versicherungen).
                </p>
              </li>
              <li data-list-text="&bull;">
                <p>
                  Dritte im Rahmen unserer gesetzlichen oder vertraglichen
                  Verpflichtungen, Beh&ouml;rden, staatliche Einrichtungen,
                  Gerichte.
                </p>
              </li>
            </ul>
            <p>
              Mit Dienstleistern, die in unserem Auftrag Personendaten
              bearbeiten, schliessen wir Vertr&auml;ge, die diese verpflichten,
              den Datenschutz zu gew&auml;hrleisten. Unsere Dienstleister
              befinden sich mehrheitlich in der Schweiz oder in der EU / im EWR.
              Gewisse Personendaten k&ouml;nnen auch in die USA (z.B. Google
            </p>
            <p>
              Analytics-Daten) oder in Ausnahmef&auml;llen in andere L&auml;nder
              weltweit &uuml;bermittelt werden. Sollte eine
              Daten&uuml;bermittlung in andere L&auml;nder, die &uuml;ber kein
              angemessenes Datenschutzniveau verf&uuml;gen, erforderlich sein,
              erfolgt diese auf Basis der EU-Standardvertragsklauseln (z.B. im
              Falle von Google) oder anderer geeigneter Instrumente).
            </p>
          </li>
          <li data-list-text="8.">
            <h2>
              <a name="bookmark20"></a>Dauer der Aufbewahrung von Personendaten
            </h2>
            <p>
              Wir verarbeiten und speichern Ihre Personendaten, solange es
              f&uuml;r die Erf&uuml;llung unserer vertraglichen und gesetzlichen
              Pflichten oder sonst die mit der Bearbeitung verfolgten Zwecke
              erforderlich ist, d.h. also zum Beispiel f&uuml;r die Dauer der
              gesamten Gesch&auml;ftsbeziehung (von der Anbahnung, Abwicklung
              bis zur Beendigung eines Vertrags) sowie dar&uuml;ber hinaus
              gem&auml;ss den gesetzlichen Aufbewahrungs- und
              Dokumentationspflichten. Dabei ist es m&ouml;glich, dass
              Personendaten f&uuml;r die Zeit aufbewahrt werden, in der
              Anspr&uuml;che gegen unser Unternehmen geltend gemacht werden
              k&ouml;nnen (d.h. insbesondere w&auml;hrend der gesetzlichen
              Verj&auml;hrungsfrist) und soweit wir anderweitig gesetzlich dazu
              verpflichtet sind oder berechtigte Gesch&auml;ftsinteressen dies
              erfordern (z.B. f&uuml;r Beweis- und Dokumentationszwecke). Sobald
              Ihre Personendaten f&uuml;r die oben genannten Zwecke nicht mehr
              erforderlich sind, werden sie grunds&auml;tzlich und soweit
              m&ouml;glich gel&ouml;scht oder anonymisiert. F&uuml;r
              betriebliche Daten (z.B. Systemprotokolle, Logs) gelten
              grunds&auml;tzliche k&uuml;rzere Aufbewahrungsfristen von
              zw&ouml;lf Monaten oder weniger.
            </p>
          </li>
          <li data-list-text="9.">
            <h2>
              <a name="bookmark21"></a>Datensicherheit
            </h2>
            <p>
              Wir treffen angemessene technische und organisatorische
              Sicherheitsvorkehrungen zum Schutz Ihrer Personendaten vor
              unberechtigtem Zugriff und Missbrauch wie etwa der Erlass von
              Weisungen, Schulungen, IT- und Netzwerksicherheitsl&ouml;sungen,
              Zugangskontrollen und -beschr&auml;nkungen, Verschl&uuml;sselung
              von Datentr&auml;gern und &Uuml;bermittlungen, Pseudonymisierung
              und Kontrollen.
            </p>
          </li>
          <li data-list-text="10.">
            <h2>
              <a name="bookmark22"></a>Pflicht zur Bereitstellung von
              Personendaten
            </h2>
            <p>
              Im Rahmen unserer Gesch&auml;ftsbeziehung m&uuml;ssen Sie
              diejenigen Personendaten bereitstellen, die f&uuml;r die Aufnahme
              und Durchf&uuml;hrung einer Gesch&auml;ftsbeziehung und der
              Erf&uuml;llung der damit verbundenen vertraglichen Pflichten
              erforderlich sind (eine gesetzliche Pflicht, uns Daten
              bereitzustellen, haben Sie in der Regel nicht). Ohne diese Daten
              werden wir nicht in der Lage sein, einen Vertrag mit Ihnen (oder
              der Stelle oder Person, die Sie vertreten,) zu schliessen oder
              diesen abzuwickeln. Auch die Website kann nicht genutzt werden,
              wenn gewisse Angaben zur Sicherstellung des Datenverkehrs (wie
              z.B. IP-Adresse) nicht offengelegt werden.
            </p>
          </li>
          <li data-list-text="11.">
            <h2>
              <a name="bookmark23"></a>Ihre Rechte
            </h2>
            <p>
              Sie haben im Zusammen mit unserer Bearbeitung von Personendaten
              die folgenden Rechte:
            </p>
            <ul>
              <li data-list-text="">
                <p>
                  Recht auf Auskunft &uuml;ber zu Ihnen bei uns gespeicherte
                  Personendaten, den Zweck der Bearbeitung, die Herkunft sowie
                  &uuml;ber Empf&auml;nger oder Kategorien von Empf&auml;ngern,
                  an die Personendaten weitergegeben werden.
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Recht auf Berichtigung, falls Ihre Daten nicht korrekt oder
                  nicht vollst&auml;ndig sind.
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Recht auf Einschr&auml;nkung der Bearbeitung Ihrer
                  Personendaten
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Recht, die L&ouml;schung der bearbeiteten Personendaten zu
                  verlangen
                </p>
              </li>
              <li data-list-text="">
                <p>Recht auf Daten&uuml;bertragbarkeit</p>
              </li>
              <li data-list-text="">
                <p>
                  Recht einer Datenbearbeitung zu widersprechen oder eine
                  Einwilligung zur Bearbeitung von Personendaten jederzeit ohne
                  Angabe von Gr&uuml;nden zu widerrufen.
                </p>
              </li>
              <li data-list-text="">
                <p>
                  Recht auf Beschwerde an eine zust&auml;ndige
                  Aufsichtsbeh&ouml;rde, sofern rechtlich vorgesehen.
                </p>
              </li>
            </ul>
            <p>
              Um diese Rechte geltend zu machen, wenden Sie sich an die unter
              Ziff. 1 angegebene Adresse.
            </p>
            <p>
              Bitte beachten Sie aber, dass wir uns vorbehalten, unsererseits
              die gesetzlich vorgesehenen Einschr&auml;nkungen geltend zu
              machen, etwa wenn wir zur Aufbewahrung oder Bearbeitung gewisser
              Daten verpflichtet sind, daran ein &uuml;berwiegendes Interesse
              haben (soweit wir uns darauf berufen d&uuml;rfen) oder sie
              f&uuml;r die Geltendmachung von Anspr&uuml;chen ben&ouml;tigen.
              Falls Kosten f&uuml;r Sie anfallen, werden wir Sie vorab
              informieren.
            </p>
          </li>
          <li data-list-text="12.">
            <h2>
              <a name="bookmark24"></a>&Auml;nderung der
              Datenschutzerkl&auml;rung
            </h2>
          </li>
        </ol>
        <p>
          Wir behalten uns die jederzeitige &Auml;nderung dieser
          Datenschutzerkl&auml;rung ausdr&uuml;cklich vor. Letzte &Auml;nderung:
          August 2023
        </p>
      </section>
    </Layout>
  )
}

export default Datenschutz
